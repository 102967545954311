import "./event_popup.html";

const FOCUSABLE_ELEMENTS = [
	"a[href]",
	"button:not([disabled])",
	"textarea",
	'input[type="text"]',
	'input[type="radio"]',
	'input[type="checkbox"]',
	"select",
	"[tabindex]",
].join(",");

export class EventPopupManager {
	/**
	 * @typedef {import('@fullcalendar/core').EventApi} EventApi
	 *
	 * @typedef {EventApi & { el: HTMLElement }} EventApiWithElement
	 *
	 * @typedef {object} EventPopupOptions
	 * @property {string} doctype
	 * @property {string} container
	 * @property {boolean} [fetch]
	 */

	constructor(/** @type {EventPopupOptions} */ options) {
		const requiredOptions = ["doctype", "container"];
		for (const option of requiredOptions) {
			if (!options[option]) {
				console.error(`Calendar View: EventPopupManager: Option '${option}' is required`);
			}
		}
		this.options = options;
		this.lastPopup = null;
	}

	async setup() {
		await frappe.model.with_doctype(this.options.doctype);
		this.meta = frappe.get_meta(this.options.doctype);

		frappe.ui.keys.on("escape", this.onEscape.bind(this));
	}

	/** @private */
	onEscape() {
		if (this.lastPopup) {
			this.hideEventPopup(this.lastPopup);
		}
	}

	/** @private */
	getFields() {
		return this.meta.fields
			.filter((df) => {
				if (["subject", "starts_on", "ends_on", "all_day"].includes(df.fieldname)) {
					return false;
				}
				if (df.in_list_view || df.bold) {
					return true;
				}
			})
			.map((df) => ({
				...df,
				reqd: false,
				read_only: true,
				readonly_depends_on: "",
			}));
	}

	addEventPopup(/** @type {EventApiWithElement} */ info) {
		info.el.ariaLabel = __("Click to view details");
		info.el.href = frappe.utils.get_form_link(this.options.doctype, info.event.id);
		// this.showEventPopup(info);

		info.el.addEventListener("click", (event) => {
			if (
				event.metaKey ||
				event.ctrlKey ||
				event.shiftKey ||
				event.altKey ||
				event.button !== 0 ||
				!event.pointerType
			) {
				return;
			}

			event.preventDefault();
			event.stopPropagation();
			this.toggleEventPopup(info);
		});

		info.el.addEventListener("dblclick", () => {
			info.el.click();
			this.hideEventPopup(info);
		});

		// this.toggleEventPopup(info); // debug
	}

	removeEventPopup(/** @type {EventApiWithElement} */ info) {
		this.hideEventPopup(info);
	}

	/** @private */
	toggleEventPopup(/** @type {EventApiWithElement} */ info) {
		if (info.el._eventPopup) {
			this.hideEventPopup(info);
		} else {
			this.showEventPopup(info);
		}
	}

	/** @private */
	async showEventPopup(/** @type {EventApiWithElement} */ info) {
		if (this.lastPopup) {
			this.hideEventPopup(this.lastPopup);
			this.lastPopup = null;
		}
		if (!info.el._eventPopup) {
			await this.buildEventPopup(info);
			info.el._eventPopup = true;
			this.lastPopup = info;
		}
		$(info.el).popover("show");
	}

	/** @private */
	hideEventPopup(/** @type {EventApiWithElement} */ info) {
		if (info.el._eventPopup) {
			info.el._eventPopup = null; // Set it first to avoid infinite recursion
			$(info.el).popover("hide");
			this.lastPopup = null;
		}
		this.backdrop?.remove();
		this.backdrop = null;
	}

	/** @private */
	async buildEventPopup(/** @type {EventApiWithElement} */ info) {
		const body = document.createElement("div");
		body.innerHTML = frappe.render_template("event_popup", {});

		const qs = (selector) => {
			/** @type {HTMLElement | null} */
			const element = body.querySelector(selector);
			if (!element) {
				console.error(`Calendar View: EventPopupManager: Element '${selector}' not found`);
			}
			return element;
		};

		const buttons = {
			close: qs("[data-action='close']"),
			delete: qs("[data-action='delete']"),
			edit: qs("[data-action='edit']"),
		};

		const elements = {
			subject: qs("[data-key='subject']"),
			timestamp: qs("[data-key='timestamp']"),
			swatch: qs("[data-key='swatch']"),
			description: qs("[data-key='description']"),
			links: qs("[data-key='links']"),
			fields: qs("[data-key='fields']"),
		};

		buttons.close.title = buttons.close.ariaLabel = __("Close");
		buttons.close.addEventListener("click", () => this.hideEventPopup(info));

		buttons.delete.title = buttons.delete.ariaLabel = __("Delete");
		buttons.delete.addEventListener("click", () => {
			frappe.confirm(__("Are you sure you want to delete this event?"), () => {
				this.hideEventPopup(info);
				frappe.model.delete_doc(this.options.doctype, info.event.id, {
					callback: () => info.event.remove(),
				});
			});
		});
		if (!frappe.perm.has_perm("Event", 0, "delete")) {
			buttons.delete.remove();
		}

		buttons.edit.title = buttons.edit.ariaLabel = __("Edit");
		buttons.edit.addEventListener("click", (e) => {
			if (e.metaKey || e.ctrlKey || e.shiftKey || e.altKey || e.button !== 0) {
				return;
			}
			this.hideEventPopup(info);
			frappe.set_route("Form", this.options.doctype, info.event.id);
		});
		buttons.edit.href = frappe.utils.get_form_link(this.options.doctype, info.event.id);

		elements.swatch.style.backgroundColor = info.event.backgroundColor;

		if (info.event.title) {
			elements.subject.textContent = info.event.title;
		}

		const formatter = new Intl.DateTimeFormat(frappe.boot.lang, {
			dateStyle: "medium",
			timeStyle: info.event.allDay ? null : "short",
		});
		elements.timestamp.textContent = formatter.formatRange(info.event.start, info.event.end);

		if (strip_html(info.event.extendedProps.description).trim()) {
			elements.description.innerHTML = info.event.extendedProps.description;
			elements.description.closest(".evp-row").removeAttribute("hidden");
		}

		await this.populateAsync(info, elements);

		$(info.el).tooltip("hide");

		$(info.el).popover({
			content: body,
			container: document.body,
			boundary: "viewport",
			html: true,
			placement: "auto",
			trigger: "manual",
			template:
				'<div class="popover evp-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
		});

		$(info.el).on("shown.bs.popover", () => {
			this.trapFocus(body);
		});

		$(info.el).on("hide.bs.popover", () => {
			this.hideEventPopup(info);
		});

		this.backdrop = document.createElement("div");
		this.backdrop.classList.add("evp-backdrop");
		this.backdrop.addEventListener("click", () => this.hideEventPopup(info));
		document.body.append(this.backdrop);

		return true;
	}

	/** @private */
	async populateAsync(info, elements) {
		const doc = await frappe.model.with_doc(this.options.doctype, info.event.id);
		await this.populateFields(elements.fields, doc);
		await this.populateLinks(elements.links, doc);
	}

	/** @private */
	async populateLinks(/** @type {HTMLElement} */ element, /** @type {object} */ doc) {
		if (!doc?.event_references?.length) {
			return;
		}

		element.closest(".evp-row").removeAttribute("hidden");
		element.innerHTML = "";
		const ul = document.createElement("ul");
		ul.classList.add("p-0", "pl-4");
		element.appendChild(ul);

		const EXCLUDE_LIST = []; // DocTypes to exclude
		for (const row of doc.event_references || []) {
			if (EXCLUDE_LIST.includes(row.reference_doctype)) {
				continue;
			}

			const li = document.createElement("li");
			ul.appendChild(li);

			const anchor = document.createElement("a");
			li.appendChild(anchor);

			const url = frappe.utils.get_form_link(row.reference_doctype, row.reference_docname);
			anchor.href = url;
			anchor.textContent = row.reference_docname;
			anchor.title = __("{0}: {1}", [__(row.reference_doctype), row.reference_docname]);
			anchor.classList.add("underline");
		}
	}

	/** @private */
	async populateFields(/** @type {HTMLElement} */ element, /** @type {object} */ doc) {
		for (const df of this.getFields()) {
			const wrapper = document.createElement("div");
			wrapper.classList.add("evp-row", "evp-row--field");
			element.before(wrapper);
			const control = frappe.ui.form.make_control({
				df: df,
				parent: wrapper,
				render_input: true,
				doc: doc,
			});
		}
	}

	trapFocus(body) {
		const focusableElements = Array.from(body.querySelectorAll(FOCUSABLE_ELEMENTS)).filter(
			(element) => $(element).is(":visible")
		);
		const firstElement = focusableElements[0];
		const lastElement = focusableElements[focusableElements.length - 1];

		body.addEventListener("keydown", (event) => {
			if (!document.activeElement) return;
			if (event.key !== "Tab") return;

			if (event.shiftKey && document.activeElement === firstElement) {
				event.preventDefault();
				lastElement.focus();
			}

			if (!event.shiftKey && document.activeElement === lastElement) {
				event.preventDefault();
				firstElement.focus();
			}
		});

		firstElement?.focus();
	}
}
