
	frappe.templates['event_popup'] = `<article class="evp-root">
	<div class="evp-toolbar">
		<button data-action="close" type="button" class="btn-reset evp-btn">
			{{ frappe.utils.icon("es-line-close", "md") }}
		</button>
		<div class="evp-inner-toolbar">
			<a data-action="edit" role="button" type="button" class="btn-reset evp-btn">
				{{ frappe.utils.icon("es-line-edit-alt", "md") }}
			</a>
			<button data-action="delete" type="button" class="btn-reset evp-btn">
				{{ frappe.utils.icon("es-line-delete", "md") }}
			</button>
		</div>
	</div>
	<div class="evp-scroller" tabindex="0">
		<div class="evp-row">
			<div>
				<div class="evp-swatch" data-key="swatch"></div>
			</div>
			<div>
				<div data-key="subject" role="heading" aria-level="1"></div>
				<div data-key="timestamp"></div>
			</div>
		</div>
		<div style="height: 1em;"></div>
		<div class="evp-row" hidden>
			<div aria-label="{{ __('Description') }}" title="{{ __('Description') }}">
				{{ frappe.utils.icon("es-line-align", "md") }}
			</div>
			<div class="evp-scroller" style="max-height: 150px;" tabindex="0">
				<div data-key="description"></div>
			</div>
		</div>
		<div class="evp-row" hidden>
			<div aria-label="{{ __('Attachments') }}" title="{{ __('Attachments') }}">
				{{ frappe.utils.icon("attachment", "md") }}
			</div>
			<div>
				<div data-key="links"></div>
			</div>
		</div>
		<template hidden data-key="fields"></template>
	</div>
</article>
`;
